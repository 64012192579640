@charset "UTF-8";
/*プリントTシャツ固有のCSSはここへ書く*/

* {
    box-sizing: border-box;
}

.print_t_shirt_top {
    font-family: 'Zen Kaku Gothic New', 'Roboto', sans-serif;
}
.print_t_shirt_top a {
    text-decoration: none;
}

h1,
#header,
.header-nav ul li,
.foot_menu,
address {
    font-family: Avenir, "Open Sans", "Helvetica Neue", Helvetica, Arial, Verdana, Roboto, "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo UI", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

#header {
    margin-bottom: 0;
}

.header-nav {
    z-index: 5;
}

.hdInner {
    padding-top: 20px;
    padding-bottom: 20px;
}

/* main */

#print_t_shirt-wrapper {
    clear: both;
    width: 1200px;
    max-width: 100%;
    margin-top: 8px;
    color: #2b2b2f;
}

#contents {
    background-image: url(../../../../public/img/products/print_t_shirt/repeat_grit.png), url(../../../../public/img/products/print_t_shirt/repeat_grit-2.png);
    background-repeat: repeat-y, repeat-y;
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    background-position: top right, top left;
    height: calc(100vh - 105px);
    background-size: 40px;
}

.top-main {
    display: flex;
    height: calc(100% - 50px - 6%);
    position: relative;
    max-width: 75%;
    align-items: center;
    justify-content: center;
}

.top-main-left {
    max-height: 100%;
}

.top-main-right {
    max-height: 100%;
    padding: 8% 3% 0;
}

.scroll {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

/* main終わり */

/* section全体 */

.makes-type,
.example-type,
.voice-type {
    display: flex;
    justify-content: space-around;
    column-gap: 60px;
    row-gap: 60px;
    width: 1200px;
    margin: auto;
    max-width: 100%;
    flex-wrap: wrap;
}

/* 1.makes */
.makes {
    background-image: url(/img/products/print_t_shirt/bg.png);
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    background-repeat: round;
    background-size: cover;
    padding-bottom: 180px;
}

.makes-box {
    width: 360px;
    height: 383px;
    background-color: #fcfcfc;
    padding: 25px 40px;
}

/* 2.process */

.process {
    position: relative;
}

.person-img-1 {
    position: absolute;
    bottom: -20%;
    left: 10%;
    z-index: 5;
}

/* 3.choice-color */

.choice-color {
    background-image: url(/img/products/print_t_shirt/bg-2.png);
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    background-repeat: round;
    background-size: cover;
    position: relative;
    padding-bottom: 180px;
}

.slider-img {
    width: 290px;
    display: block;
    margin: auto;
}

.slick-slide img {
    width: 290px;
}

.color-choice {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 17px;
    width: 588px;
    margin: auto;
}

.color-choice button:hover {
    border: #e02d96 solid 2px;
    border-radius: 4px;
}

.panel-item {
    border: 2px solid transparent;
    border-radius: 4px;
}

.panel-item.is-active {
    border: #e02d96 solid 2px;
}

.person-img-2 {
    position: absolute;
    bottom: 0;
    right: 20%;
}


/* 4.price */

.width580 {
    width: 580px;
    margin: auto;
}

.t-shirts-price {
    border: 2px solid #2b2b2b;
    border-right: none;
    border-left: none;
    border-collapse: collapse;
}

td, th {
    border: 1px solid #2b2b2b;
    border-collapse: collapse;
    border-right: none;
    border-left: none;
    text-align: left;
}

th {
    font-size: 14px;
    font-weight: bold;
}

td {
    font-size: 14px;
}

td, th {
    padding: 8px;
    width: 120px;
    height: 60px;
}

th:nth-of-type(even){
    background: #f4f3f3;
}

td:nth-of-type(even) {
    background-color: #f4f3f3;
}

/* 5.delivery-flow */

.delivery-flow {
    background-image: url(/img/products/print_t_shirt/bg-2.png);
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    background-repeat: round;
    background-size: cover;
    position: relative;
    padding-bottom: 180px;
}

.person-img-3 {
    position: absolute;
    bottom: 0.1%;
    left: 16%;
}

.flow-chart li {
    background-color: #fcfcfc;
    font-weight: bold;
    padding: 20px;
    margin: auto;
}

.flow-chart li span a {
    color: #e02d96;
    text-decoration: underline;
}

.arrow {
    height: 33px;
}

.pink-finish {
    color: #e02d96;
    font-weight: bold;
}

/* 6.production-example */

.example-box {
    width: 360px;
    height: 440px;
    background-color: #f4f3f3;
    padding: 25px 20px 30px;
}

/* 7.voice */

.voice {
    background-image: url(/img/products/print_t_shirt/bg-2.png);
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    background-repeat: round;
    background-size: cover;
    position: relative;
    padding-bottom: 150px;
}

.voice-t-1,
.voice-t-2,
.voice-t-3 {
    background-color: #fff;
    width: 360px;
    height: 168px;
    position: relative;
    margin-top: 70px;
}

.voice-t-1 {
    border: #17CAE6 solid 2px;
}

.voice-t-2 {
    border: #e02d96 solid 2px;
}

.voice-t-3 {
    border: #f5e400 solid 2px;
}

.voice-t-1 p,
.voice-t-2 p,
.voice-t-3 p {
    padding: 30px 37px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
}

.voice-t-1 img,
.voice-t-2 img,
.voice-t-3 img {
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translate(-50%);
    width: 100px;
}


.person-img-4 {
    position: absolute;
    bottom: -15%;
    right: 15%;
}

/* 8.question */

.anytime-question a::after {
    content: url(/images/arrow_pink.png?b211904…);
    display: flex;
    align-items: center;
    margin-left: auto;
}

.question {
    position: relative;
    padding-bottom: 100px;
}

.person-img-5 {
    position: absolute;
    bottom: -10%;
    left: 10%;
}

/* 9.news */

.print_t_shirt-news {
    background-image: url(/img/products/print_t_shirt/bg-3.png);
    padding-bottom: 120px;
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    background-size: cover;
}

.p_t-question,
.p_t-news {
    width: 580px;
}

.anytime-question {
    /* margin-bottom: 20px; */
    background-color: #fcfcfc;
    border: #f4f3f3 1px solid;
    font-size: 14px;
    position: relative;
    cursor: pointer;
}

.anytime-question:hover {
    opacity: 0.8;
}

.question .p_t-question .accordion_inner {
    display: none;
    padding: 30px 30px;
    /* margin-bottom: 20px; */
    background-color: #fff;
    border-left: 1px solid #e02d96;
    border-right: 1px solid #e02d96;
    box-sizing: border-box;
}

.question .p_t-question .accordion_inner.stay {
    display: block;
}

.anytime-question a {
    padding: 18px;
}

.notice span {
    width: 72px;
}

.anytime-question a {
    color: #2b2b2f;
    display: flex;
    width: 100%;
}

.notice a::after {
    content: url(../../../../public/img/products/print_t_shirt/arrow_pink.png);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;    }

.text-border a {
    border-bottom: 1px solid#e02d96;
    color: #e02d96;
    font-size: 16px;
    font-weight: bold;
}

.notice a {
    color: #2b2b2f;
    display: flex;
    width: 100%;
    padding: 18px 50px 18px 18px;
    position: relative;
}
.notice a:hover .news_title {
    color: #e02d96;
}

.notice {
    margin-bottom: 20px;
    background-color: #fcfcfc;
    border: #f4f3f3 1px solid;
    font-size: 14px;
    position: relative;
}

.notice div {
    width: calc(100% - 72px);
    word-wrap: break-word;
    overflow-wrap: break-word;
}
.news_title {
    font-weight: bold;
    margin-bottom: 8px;
}


/* hoverの透過 */

#global ul li a:hover,
.phone a:hover,
.mail:hover,
.estimate:hover,
.delivery-flow li a:hover,
.text-border:hover,
.right-now a:hover,
.free-estimate a:hover,
.production-e-more a:hover,
.voice-more a:hover,
.question-all a:hover {
    opacity: 0.7;
}

/* footer */

.footer {
    margin-top: -50px;
}
